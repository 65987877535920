import React from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';

import * as CONFIG from '../../SiteConfig';

import Layout from '../components/templates/Default';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/particles/Session';
import { UserList } from '../components/particles/Users';
import * as ROLES from '../components/particles/constants/roles';

const AdminPageBase = () => (
  <>
    <section className="grid">
      <h1>Admin</h1>
      <p>
        The Admin Page is accessible by every signed in admin user.
      </p>
      <UserList />
    </section>
  </>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPageBase);

export default () => (
  <Layout>
    <Helmet
      title={`${CONFIG.SITE_TITLE} | Administrative Page`}
      defer={false}
    />
    <AdminPage />
  </Layout>
);
